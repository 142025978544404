<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '600',
            }"
        >
            <el-table-column width="8"> </el-table-column>
            <el-table-column label="创建时间" show-overflow-tooltip width="93">
                <template slot-scope="scope">
                    <div
                        :style="{
                            color: scope.row.contractStatus == 3 ? 'red' : '',
                        }"
                    >
                        {{
                            scope.row.createTime
                                ? scope.row.createTime.slice(0, 10)
                                : '- -' || '- -'
                        }}
                    </div>
                </template>
            </el-table-column>

            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="回款金额" show-overflow-tooltip width="80">
                <template slot-scope="scope">
                    <div
                        :style="{
                            color: scope.row.contractStatus == 3 ? 'red' : '',
                        }"
                    >
                        {{ scope.row.returnAmount || 0 }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="客户名称" show-overflow-tooltip width="239">
                <template slot-scope="scope">
                    <div
                        :style="{
                            color: scope.row.contractStatus == 3 ? 'red' : '',
                        }"
                    >
                        {{ scope.row.companyName }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="合同编号" show-overflow-tooltip width="125">
                <template slot-scope="scope">
                    <div
                        v-if="scope.row.contractStatus == 3"
                        :style="{
                            color: 'red',
                            cursor: 'pointer',
                        }"
                    >
                        {{ scope.row.contractNo }}
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="该回款的关联合同已作废"
                            placement="bottom"
                        >
                            <i
                                v-if="scope.row.contractStatus == 3"
                                class="el-icon-warning"
                                style="color: #ff912a"
                            ></i>
                        </el-tooltip>
                    </div>
                    <div
                        v-else
                        @click="onDetail(scope.row)"
                        :style="{
                            color: '#2370eb',
                            cursor: 'pointer',
                        }"
                    >
                        {{ scope.row.contractNo }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="部门"
                show-overflow-tooltip
                v-if="btnP.depSearch"
                width="80"
            >
                <template slot-scope="scope">
                    <el-tooltip placement="top">
                        <div slot="content">
                            <p
                                style="line-height: 2"
                                v-for="item in scope.row.departmentAdminDOList"
                                :key="item.id"
                            >
                                {{ item.departmentName }}
                            </p>
                        </div>
                        <div
                            :style="{
                                color:
                                    scope.row.contractStatus == 3 ? 'red' : '',
                            }"
                        >
                            {{ returnName(scope.row) }}
                        </div>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="所属人"
                show-overflow-tooltip
                v-if="btnP.searchUser"
                width="55"
            >
                <template slot-scope="scope">
                    <div
                        :style="{
                            color: scope.row.contractStatus == 3 ? 'red' : '',
                        }"
                    >
                        {{ scope.row.adminName || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="收款码"
                show-overflow-tooltip
                v-if="listType == '回款列表'"
                width="55"
            >
                <template
                    slot-scope="scope"
                    v-if="
                        scope.row.contractStatus != 3 &&
                        scope.row.returnStatus == 1
                    "
                >
                    <div>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            placement="bottom"
                        >
                            <div slot="content">
                                <img
                                    :src="scope.row.qrcodeUrl"
                                    style="width: 150px; height: 150px"
                                    alt=""
                                />
                            </div>
                            <el-button style="color: #2370eb" type="text">
                                预览
                            </el-button>
                        </el-tooltip>
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="操作" fixed="right" width="130">
                <template
                    slot-scope="scope"
                    v-if="scope.row.contractStatus != 3"
                >
                    <el-button
                        style="color: #2370eb"
                        type="text"
                        v-if="scope.row.returnStatus == 1 && btnP.upData"
                        @click="onUpdate(scope.row)"
                    >
                        编辑
                    </el-button>
                    <el-button
                        style="color: #2370eb"
                        type="text"
                        v-if="
                            scope.row.returnStatus == 1 &&
                            scope.row.adminId == adminId &&
                            btnP.matching
                        "
                        @click="onMatching(scope.row)"
                    >
                        匹配
                    </el-button>
                    <el-button
                        style="color: red"
                        type="text"
                        v-if="
                            scope.row.returnStatus == 1 &&
                            scope.row.billingStatus == 1 &&
                            btnP.delMoney
                        "
                        @click="onDel(scope.row)"
                    >
                        删除
                    </el-button>
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>

<script>
import {
    planList,
    getWater as waterList,
    planBrief,
    customerBrief,
} from '@/api/contr/money.js';

export default {
    name: '',
    props: {},
    data() {
        return {
            tableData: [],
            listType: '',
            btnP: {},
            adminId: sessionStorage.getItem('adminId'),
        };
    },
    components: {},
    created() {},
    methods: {
        returnName(row) {
            var str = '';
            row.departmentAdminDOList.forEach((item) => {
                if (item.main == 1) {
                    str = item.departmentName;
                }
            });
            return str;
        },
        getData(listType, searchVal, pageNum, pageSize, btnP) {
            this.btnP = btnP;
            // console.log(btnP);
            this.listType = listType;

            this.tableData = [];
            this.planBrief(searchVal);
            this.customerBrief(searchVal);

            let data = {
                param: {
                    returnStatus: 1,
                },
                pageNum: pageNum,
                pageSize: pageSize,
            };
            if (searchVal.name) {
                data.param.name = searchVal.name;
            }
            if (searchVal.returnTime) {
                data.param.returnTime = searchVal.returnTime;
            }

            data.param.contractStatusList = [7, 8, 10, 11, 12];

            if (!btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            }
            if (!btnP.searchUser && !btnP.depSearch) {
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            if (searchVal.departmentId) {
                data.param.departmentId = searchVal.departmentId;
            }
            if (searchVal.adminId) {
                data.param.adminId = searchVal.adminId;
            }
            planList(data).then((res) => {
                if (res.code == 200) {
                    this.tableData = res.data.list;
                    this.$emit('totalNum', res.data.total);
                }
            });
        },
        onUpdate(data) {
            this.$emit('onUpdate', data);
        },
        onAdd(data) {
            this.$emit('onAdd', data);
        },
        onApply(data) {
            this.$emit('onApply', data);
        },
        onDetail(data) {
            this.$emit('onDetail', data);
        },
        onMatching(data) {
            this.$emit('onMatching', data);
        },
        onDel(data) {
            this.$emit('onDel', data);
        },
        planBrief(searchVal) {
            let data = {
                param: {},
            };
            if (searchVal.name) {
                data.param.name = searchVal.name;
            }
            if (searchVal.returnTime) {
                data.param.returnTime = searchVal.returnTime;
            }
            if (!this.btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            }
            if (!this.btnP.searchUser) {
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            if (searchVal.status == '异常') {
                data.param.contractStatus = 3;
            } else {
                data.param.contractStatusList = [7, 8, 10, 11, 12];
            }
            if (searchVal.departmentId) {
                data.param.departmentId = searchVal.departmentId;
            }
            if (searchVal.adminId) {
                data.param.adminId = searchVal.adminId;
            }
            planBrief(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.$emit('briefData', res.data);
                }
            });
        },
        customerBrief(searchVal) {
            let data = {
                param: {
                    returnStatus: 1,
                },
            };
            if (searchVal.name) {
                data.param.name = searchVal.name;
            }
            if (searchVal.returnTime) {
                data.param.returnTime = searchVal.returnTime;
            }
            if (!this.btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            }
            if (!this.btnP.searchUser) {
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            if (searchVal.status == '异常') {
                data.param.contractStatus = 3;
            } else {
                data.param.contractStatusList = [7, 8, 10, 11, 12];
            }
            if (searchVal.departmentId) {
                data.param.departmentId = searchVal.departmentId;
            }
            if (searchVal.adminId) {
                data.param.adminId = searchVal.adminId;
            }
            customerBrief(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.$emit('briefData2', res.data);
                }
            });
        },
        getMaxLength(arr) {
            return arr.reduce((acc, item) => {
                if (item) {
                    const calcLen = this.getTextWidth(item);
                    if (acc < calcLen) {
                        acc = calcLen;
                    }
                }
                return acc;
            }, 0);
        },
        /**
         * 使用span标签包裹内容，然后计算span的宽度 width： px
         * @param valArr
         */
        getTextWidth(str) {
            console.log(str);
            let width = 0;
            const html = document.createElement('span');
            html.innerText = str;
            html.className = 'getTextWidth';
            document.querySelector('body').appendChild(html);
            width = document.querySelector('.getTextWidth').offsetWidth;
            document.querySelector('.getTextWidth').remove();
            return width;
        },

        flexColumnWidth(label, prop) {
            // console.log('label', label)
            // console.log('prop', prop)
            // 1.获取该列的所有数据
            const arr = this.tableData.map((x) => x[prop]);
            arr.push(label); // 把每列的表头也加进去算
            console.log(arr);
            // 2.计算每列内容最大的宽度 + 表格的内间距（依据实际情况而定）
            return this.getMaxLength(arr) + 20 + 'px';
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.icon-shangchuanshibaixian-copy {
    font-size: 14px;
    color: #f7b84f;
}
.table-box {
    width: 100%;
    height: 100%;
    .tableBtn-box {
        display: flex;
        align-items: center;
        justify-items: center;
        height: 40px;
    }
    .tableBtn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        margin-left: 10px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        height: 40px;
        button {
            width: 20px;
            height: 20px;
            margin: 0;
            border-radius: 0;
            background: transparent;
            border: none;
            position: relative;
        }
        button:nth-child(1) {
            line-height: 2;
        }
        button:nth-child(2) {
            /deep/ .el-icon-caret-bottom {
                position: absolute;
                top: -3px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    /deep/ .disableheadselection > .cell .el-checkbox__inner {
        display: none;
    }
    .el-table {
        /deep/tr td:nth-child(1) .cell,
        /deep/tr td:nth-child(7) .cell {
            padding-right: 20px;
            position: relative;
            .mini_circle {
                content: '';
                position: absolute;
                top: 2px;
                right: 0;
                width: 20px;
                height: 20px;
                background: #fff;
                border: 1px solid #ff760f;
                font-size: 12px;
                border-radius: 50%;
                font-style: normal;
                text-align: center;
                line-height: 20px;
                transform: scale(0.8);
                color: #ff760f;
            }
        }
    }
}
.tooltip_con_bot {
    //   padding-top: 30px;
    p {
        margin-bottom: 5px;
        span {
            display: inline-block;
            width: 60px;
        }
    }
}

.left_toast {
    position: absolute;
    top: 0;
    left: 10px;
    font-size: 12px;
    line-height: 1.2;
}

.el-table /deep/ th {
    // padding: 0;
    white-space: nowrap;
    min-width: fit-content;
}

.el-table /deep/ td {
    // padding: 1px;
    white-space: nowrap;
    width: fit-content;
}

/** 修改el-card默认paddingL:20px-内边距 **/
>>> .el-card__body {
    padding: 10px;
}

.el-table /deep/ .cell {
    white-space: nowrap;
    width: fit-content;
}
</style>
