var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-box"},[_c('el-table',{ref:"multipleTable",attrs:{"data":_vm.tableData,"height":"100%","header-cell-style":{
            background: '#FAFAFA',
            color: '#333333',
            'font-size': '14px',
            'font-weight': '600',
        }}},[_c('el-table-column',{attrs:{"width":"8"}}),_c('el-table-column',{attrs:{"label":"创建时间","show-overflow-tooltip":"","width":"93"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({
                        color: scope.row.contractStatus == 3 ? 'red' : '',
                    })},[_vm._v(" "+_vm._s(scope.row.createTime ? scope.row.createTime.slice(0, 10) : '- -' || '- -')+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"label":"回款金额","show-overflow-tooltip":"","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({
                        color: scope.row.contractStatus == 3 ? 'red' : '',
                    })},[_vm._v(" "+_vm._s(scope.row.returnAmount || 0)+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"label":"客户名称","show-overflow-tooltip":"","width":"239"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({
                        color: scope.row.contractStatus == 3 ? 'red' : '',
                    })},[_vm._v(" "+_vm._s(scope.row.companyName)+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"label":"合同编号","show-overflow-tooltip":"","width":"125"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.contractStatus == 3)?_c('div',{style:({
                        color: 'red',
                        cursor: 'pointer',
                    })},[_vm._v(" "+_vm._s(scope.row.contractNo)+" "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"该回款的关联合同已作废","placement":"bottom"}},[(scope.row.contractStatus == 3)?_c('i',{staticClass:"el-icon-warning",staticStyle:{"color":"#ff912a"}}):_vm._e()])],1):_c('div',{style:({
                        color: '#2370eb',
                        cursor: 'pointer',
                    }),on:{"click":function($event){return _vm.onDetail(scope.row)}}},[_vm._v(" "+_vm._s(scope.row.contractNo)+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),(_vm.btnP.depSearch)?_c('el-table-column',{attrs:{"label":"部门","show-overflow-tooltip":"","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{attrs:{"placement":"top"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},_vm._l((scope.row.departmentAdminDOList),function(item){return _c('p',{key:item.id,staticStyle:{"line-height":"2"}},[_vm._v(" "+_vm._s(item.departmentName)+" ")])}),0),_c('div',{style:({
                            color:
                                scope.row.contractStatus == 3 ? 'red' : '',
                        })},[_vm._v(" "+_vm._s(_vm.returnName(scope.row))+" ")])])]}}],null,false,3593949854)}):_vm._e(),_c('el-table-column',{attrs:{"min-width":"20"}}),(_vm.btnP.searchUser)?_c('el-table-column',{attrs:{"label":"所属人","show-overflow-tooltip":"","width":"55"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({
                        color: scope.row.contractStatus == 3 ? 'red' : '',
                    })},[_vm._v(" "+_vm._s(scope.row.adminName || '- -')+" ")])]}}],null,false,2947765361)}):_vm._e(),_c('el-table-column',{attrs:{"min-width":"20"}}),(_vm.listType == '回款列表')?_c('el-table-column',{attrs:{"label":"收款码","show-overflow-tooltip":"","width":"55"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return (
                    scope.row.contractStatus != 3 &&
                    scope.row.returnStatus == 1
                )?[_c('div',[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","placement":"bottom"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('img',{staticStyle:{"width":"150px","height":"150px"},attrs:{"src":scope.row.qrcodeUrl,"alt":""}})]),_c('el-button',{staticStyle:{"color":"#2370eb"},attrs:{"type":"text"}},[_vm._v(" 预览 ")])],1)],1)]:undefined}}],null,true)}):_vm._e(),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"label":"操作","fixed":"right","width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return (scope.row.contractStatus != 3)?[(scope.row.returnStatus == 1 && _vm.btnP.upData)?_c('el-button',{staticStyle:{"color":"#2370eb"},attrs:{"type":"text"},on:{"click":function($event){return _vm.onUpdate(scope.row)}}},[_vm._v(" 编辑 ")]):_vm._e(),(
                        scope.row.returnStatus == 1 &&
                        scope.row.adminId == _vm.adminId &&
                        _vm.btnP.matching
                    )?_c('el-button',{staticStyle:{"color":"#2370eb"},attrs:{"type":"text"},on:{"click":function($event){return _vm.onMatching(scope.row)}}},[_vm._v(" 匹配 ")]):_vm._e(),(
                        scope.row.returnStatus == 1 &&
                        scope.row.billingStatus == 1 &&
                        _vm.btnP.delMoney
                    )?_c('el-button',{staticStyle:{"color":"red"},attrs:{"type":"text"},on:{"click":function($event){return _vm.onDel(scope.row)}}},[_vm._v(" 删除 ")]):_vm._e()]:undefined}}],null,true)}),_c('div',{staticClass:"empty",attrs:{"slot":"empty"},slot:"empty"},[_c('img',{attrs:{"src":require("@/assets/zanwushuju.png")}}),_c('span',{staticStyle:{"display":"block"}},[_vm._v("暂无数据～")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }