<template>
    <!-- 申请发票 -->
    <div class="apply_invoice_box">
        <div class="base_info">
            <div class="contr_box">
                <div class="title">
                    <p><span></span>合同信息</p>
                </div>
                <el-form
                    label-width="70px"
                    label-position="left"
                    style="margin-left: 22px"
                    size="small"
                    ref="form"
                >
                    <div>
                        <el-form-item label="客户" required>
                            <el-select
                                filterable
                                v-model="customer"
                                style="width: 240px; margin-right: 27px"
                                clearable
                                placeholder="请选择"
                                @change="getRepresentA()"
                            >
                                <el-option
                                    v-for="item in customerData"
                                    :key="item.id"
                                    :label="item.companyName"
                                    :value="id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
            <Flow ref="flow" @onAddPlan="onAddPlan"></Flow>
            <Info ref="info"></Info>
        </div>
        <div class="footer_btn">
            <el-button size="mini" round @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit" size="mini" round
                >保 存</el-button
            >
        </div>
    </div>
</template>

<script>
import ContractInfo from '../add/components/contactInfo.vue';
import Flow from './components/flow.vue';
import Info from './components/info.vue';
export default {
    name: '',
    props: {},
    data() {
        return {
            customerData: [],
        };
    },
    components: {
        ContractInfo,
        Flow,
        Info,
    },
    created() {
        this.getCustomerData();
    },
    methods: {
        // 客户列表
        getCustomerData() {
            let data = {
                param: {
                    adminId: sessionStorage.getItem('adminId'),
                },
                pageNum: 0,
                pageSize: 0,
            };
            customerList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.customerData = res.data.list;
                }
            });
        },
        getData(row, disabled) {
            this.$refs.contractInfo.getData(row, disabled);
        },
        onClose() {
            this.$emit('close');
        },
        onSubmit() {
            let data = {
                param: {},
            };
            this.$emit('onSubmit', data);
        },
        onAddPlan() {
            this.$emit('onAddPlan');
        },
    },
};
</script>

<style lang="scss" scoped>
.apply_invoice_box {
    width: 100%;
    height: 100%;
    flex-direction: column;
    display: flex;
    .base_info {
        height: calc(100% - 51px);
        overflow-y: auto;
        .contr_box {
            .title {
                p {
                    padding: 16px 0;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    color: #333333;
                    font-weight: 600;
                }
                span {
                    display: inline-block;
                    width: 2px;
                    height: 16px;
                    background: #2370eb;
                    border-radius: 1px 1px 0px 0px;
                    margin-right: 22px;
                }
            }
        }
    }
    .footer_btn {
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: right;
        border-top: 1px solid #e5e5e5;
        background-color: #fff;
        .el-button--primary {
            background-color: #2370eb;
            border-color: #2370eb;
        }
        button:last-child {
            margin-right: 30px;
        }
    }
}
</style>
