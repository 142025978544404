<template>
  <div class="flow_box">
    <p class="title">
      请选择需要开票的回款流水
      <el-button @click="onAddPlan">+ 新增计划</el-button>
    </p>
    <el-table
      ref="multipleTable"
      :data="tableData"
      height="100%"
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#333333',
        'font-size': '14px',
        'font-weight': '600',
      }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="30"> </el-table-column>
      <el-table-column prop="date" label="回款时间" width="180">
      </el-table-column>
      <el-table-column prop="name" label="付款账户" width="180">
      </el-table-column>
      <el-table-column prop="address" label="入账公司"> </el-table-column>
      <el-table-column prop="address" label="回款金额"> </el-table-column>
      <el-table-column prop="address" label="回款状态"> </el-table-column>
      <el-table-column prop="address" label="开票状态"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      tableData: [
        {
          name: "123",
        },
        {
          name: "123",
        },
        {
          name: "123",
        },
      ],
    };
  },
  components: {},
  created() {},
  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    onAddPlan() {
      this.$emit("onAddPlan");
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
/deep/ .el-table-column--selection .cell {
  padding-left: 12px;
}
.flow_box {
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  padding-bottom: 16px;
  .title {
    line-height: 1.2;
    //     margin-top: 12px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #333;
    font-weight: 600;
    border-left: 2px solid #2370eb;
    padding-left: 25px;
    button {
      background: #fff;
      border-radius: 2px;
      padding: 0 7px 0 12px;
      height: 24px;
      color: #2370eb;
      border-color: #2370eb;
      float: right;
      margin-right: 15px;
    }
  }
}
</style>
