<template>
    <div class="info_box">
        <p class="title">发票信息</p>
        <el-form label-position="left" label-width="85px" size="small">
            <el-form-item label="开票金额" required>
                <el-input
                    style="width: 240px"
                    placeholder="输入客户名称"
                    v-model="form.name"
                ></el-input>
            </el-form-item>
            <el-form-item label="开票名称" required>
                <el-input
                    style="width: 240px"
                    placeholder="输入客户名称"
                    v-model="form.name"
                ></el-input>
            </el-form-item>
            <el-form-item label="发票类型" required>
                <el-select v-model="form.type" style="width: 240px" placeholder="请选择">
                    <el-option label="增值税普通发票" :value="1"> </el-option>
                    <el-option label="增值税专用发票" :value="2"> </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="开票类型" required>
                <el-select v-model="form.type2" style="width: 240px" placeholder="请选择">
                    <el-option label="电子发票" :value="1"> </el-option>
                    <el-option label="纸质发票" :value="2"> </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="税号" required>
                <el-input
                    style="width: 240px"
                    placeholder="输入客户名称"
                    v-model="form.name"
                ></el-input>
            </el-form-item>
            <div v-show="form.type == 2">
                <el-form-item label="开户银行" required>
                    <el-input
                        style="width: 240px"
                        placeholder="输入客户名称"
                        v-model="form.name"
                    ></el-input>
                </el-form-item>
                <el-form-item label="银行账户" required>
                    <el-input
                        style="width: 240px"
                        placeholder="输入客户名称"
                        v-model="form.name"
                    ></el-input>
                </el-form-item>
                <el-form-item label="公司电话" required>
                    <el-input
                        style="width: 240px"
                        placeholder="输入客户名称"
                        v-model="form.name"
                    ></el-input>
                </el-form-item>
                <el-form-item label="注册地址" required style="width: 100%">
                    <el-input
                        style="width: 92%"
                        placeholder="输入客户名称"
                        v-model="form.name"
                    ></el-input>
                </el-form-item>
            </div>
            <el-form-item label="收件人" :required="form.type2 == 2">
                <el-input
                    style="width: 240px"
                    placeholder="输入客户名称"
                    v-model="form.name"
                ></el-input>
            </el-form-item>
            <el-form-item label="收件电话" :required="form.type2 == 2">
                <el-input
                    style="width: 240px"
                    placeholder="输入客户名称"
                    v-model="form.name"
                ></el-input>
            </el-form-item>
            <el-form-item label="收件邮箱" :required="form.type2 == 1">
                <el-input
                    style="width: 240px"
                    placeholder="输入客户名称"
                    v-model="form.name"
                ></el-input>
            </el-form-item>
            <el-form-item label="收件地址" :required="form.type2 == 2" style="width: 100%">
                <el-input
                    style="width: 92%"
                    placeholder="输入客户名称"
                    v-model="form.name"
                ></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: '',
    props: {},
    data() {
        return {
            form: {},
        };
    },
    components: {},
    created() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.info_box {
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    .title {
        line-height: 1.2;
        //     margin-top: 12px;
        margin-bottom: 20px;
        font-size: 14px;
        color: #333;
        font-weight: 600;
        border-left: 2px solid #2370eb;
        padding-left: 25px;
    }
    .el-form {
        width: 95%;
        margin: 0 auto;
        .el-form-item {
            width: 50%;
            float: left;
        }
    }
    .el-form:after {
        content: '';
        clear: both;
        display: block;
    }
}
</style>
