<template>
    <div class="clue_box">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span>回款年月</span>
                    <el-date-picker
                        v-model="searchVal.returnTime"
                        type="month"
                        size="small"
                        style="width: 220px"
                        value-format="yyyy-MM"
                        placeholder="选择年月"
                        :clearable="false"
                        @change="getTableData"
                    >
                    </el-date-picker>
                    <span>查询</span>
                    <el-input
                        size="small"
                        style="width: 180px"
                        placeholder="合同编号、客户名称"
                        v-model.trim="searchVal.name"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="getData()"
                        type="primary"
                        size="small"
                        style="margin-right: 0"
                        icon="el-icon-search"
                    ></el-button>
                </p>
                <p class="left">
                    <Department
                        ref="department"
                        :departmentWidth="225"
                        :adminWidth="220"
                        :depSearch="btnP.depSearch"
                        :searchUser="btnP.searchUser"
                        @searchData="searchData"
                    ></Department>

                    <!-- <span v-if="btnP.depSearch"
                        >部门<em style="opacity: 0">部门</em></span
                    >

                    <el-select
                        v-if="btnP.depSearch"
                        v-model="searchVal.departmentId"
                        size="small"
                        filterable
                        style="width: 225px"
                        clearable
                        placeholder="请选择"
                        @change="changeDepartmentId"
                    >
                        <el-option
                            v-for="(item, index) in departmentList"
                            v-if="index > 0"
                            :key="item.id"
                            :label="item.departmentName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                    <span v-if="btnP.searchUser"
                        >成员<em style="opacity: 0">成员</em></span
                    >

                    <el-select
                        v-if="btnP.searchUser"
                        filterable
                        style="width: 220px"
                        v-model="searchVal.adminId"
                        size="small"
                        clearable
                        placeholder="请选择"
                        @change="changeAdmin"
                    >
                        <el-option
                            v-for="item in userName"
                            :key="item.id"
                            :label="item.nickName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select> -->
                </p>
            </div>
            <div class="search_right">
                <div>
                    <p><img src="@/img/red.png" alt="" />待回款总金额</p>
                    <p
                        style="
                            padding-left: 25px;
                            font-size: 16px;
                            font-weight: 600;
                            margin-top: 20px;
                        "
                    >
                        {{ Number(bData.todoAmount) || 0 }}
                    </p>
                </div>
                <div>
                    <p><img src="@/img/green.png" alt="" />待回款客户数</p>
                    <p
                        style="
                            padding-left: 25px;
                            font-size: 16px;
                            font-weight: 600;
                            margin-top: 20px;
                        "
                    >
                        {{ cData }}
                    </p>
                </div>
            </div>
        </div>
        <div class="table-title">
            <p>
                <el-button
                    v-if="btnP.add"
                    type="primary"
                    style="
                        background: #fff;
                        border-radius: 2px;
                        padding: 0 7px 0 12px;
                        height: 24px;
                        color: #2370eb;
                        border-color: #2370eb;
                        float: right;
                    "
                    @click="addMoney({})"
                    >+ 新增计划</el-button
                >
            </p>
        </div>
        <div class="table">
            <Table
                ref="table"
                @onUpdate="onUpdate"
                @onAdd="addMoney"
                @onApply="onApply"
                @totalNum="totalNum"
                @briefData="briefData"
                @briefData2="briefData2"
                @onDetail="onDetail"
                @onMatching="onMatching"
                @onDel="onDel"
            ></Table>
        </div>

        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 匹配回款（企微收款） -->
        <el-dialog
            :visible.sync="dialogMatching"
            width="760px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    匹配回款
                </div>
            </template>
            <Matching @close="handleClose" ref="matching" />
        </el-dialog>
        <el-dialog
            :visible.sync="dialogUpdate"
            width="560px"
            :before-close="handleDialogClose"
            append-to-body
            class="dilog_box"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    编辑计划
                </div>
            </template>
            <el-form label-position="left" label-width="80px" size="small">
                <el-form-item label="回款金额" required>
                    <el-input
                        style="width: 240px"
                        placeholder="请输入"
                        v-model="form.returnAmount"
                        @input="
                            () => {
                                if (
                                    Number(form.returnAmount) >
                                    onePlan.contractAmount -
                                        allMoney +
                                        onePlan.returnAmount
                                ) {
                                    form.returnAmount = '0';
                                    this.$message.error(
                                        '回款金额必须小于合同金额'
                                    );
                                }
                                form.returnAmount = form.returnAmount.replace(
                                    /[^0-9.]/g,
                                    ''
                                );
                            }
                        "
                    ></el-input>
                    <div>
                        待计划回款金额：
                        {{
                            (
                                onePlan.contractAmount -
                                allMoney +
                                onePlan.returnAmount
                            ).toFixed(2)
                        }}
                    </div>
                </el-form-item>
                <el-form-item label="回款比例">
                    <div style="width: 240px">
                        {{
                            form.returnAmount
                                ? (
                                      (form.returnAmount /
                                          form.contractAmount) *
                                      100
                                  ).toFixed(2) + '%'
                                : '- -'
                        }}
                    </div>
                </el-form-item>
                <el-form-item label="回款年月" required>
                    <el-date-picker
                        style="width: 240px"
                        v-model="form.returnTime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        type="month"
                        placeholder="选择年月"
                        :picker-options="expireTimeOption"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="付款账户">
                    <el-input
                        style="width: 240px"
                        placeholder="请输入"
                        v-model="form.contractA"
                    ></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input
                        type="textarea"
                        rows="3"
                        style="width: 240px"
                        placeholder="请输入"
                        v-model="form.remark"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog_footer">
                <el-button @click="handleDialogClose" round size="mini"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    round
                    size="mini"
                    @click="onSubmitPlan"
                    style="background: #2370eb"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <!-- 删除计划 -->
        <el-dialog
            :visible.sync="dialogDel"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="dilog_box"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    删除计划
                </div>
            </template>
            <div style="margin: 30px 0 100px 30px; font-size: 20px">
                是否删除回款计划？
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button @click="handleClose" round size="mini"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    round
                    size="mini"
                    @click="onSubmitDel"
                    style="background: #2370eb"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <div v-if="dialogForce" class="cover">
            <div class="dialogForce_box">
                <div class="dialogForce_title">回款计划更新</div>
                <Force ref="force" @updateData="planList"></Force>
            </div>
        </div>
    </div>
</template>

<script>
import { roleList } from '@/api/customer/customer.js';
import { departmentList } from '@/api/admin/framework/framework.js';
import { updatePlan, delPlan, planList } from '@/api/contr/money.js';
import { contracGet } from '@/api/contr/contr.js';
import Table from './table/table.vue';
import Add from './add/add.vue';
import Apply from './apply/apply.vue';
import Matching from '../details/money/plan/matching.vue';
import Force from './force.vue';
import Department from '../../../components/Department';
export default {
    name: '',
    props: {},
    data() {
        return {
            searchVal: {
                followAdminId: '',
                status: '未到款',
                returnTime: this.getDate(),
            },
            currentPage: 1,
            pagesize: 20,
            total: 0,
            btnP: {},
            form: {},
            onePlan: {},
            departmentList: [],
            userName: [],
            listType: '回款列表',
            direction: 'rtl',
            drawerAdd: false,
            drawerUpdate: false,
            drawerApply: false,
            dialogUpdate: false,
            dialogForce: false,
            dialogMatching: false,
            dialogDel: false,
            bData: {},
            cData: '',
            contract: {},
            allMoney: 0,
            expireTimeOption: {
                disabledDate(date) {
                    // disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
                    return date.getTime() < Date.now();
                },
            },
        };
    },
    components: {
        Table,
        Add,
        Apply,
        Matching,
        Force,
        Department,
    },
    mounted() {
        this.getBtn();
    },
    methods: {
        planList() {
            let data = {
                param: {
                    returnStatus: 1,
                    contractStatusList: [7, 8, 10, 11, 12],
                    returnDate: this.searchVal.returnTime,
                },
                pageNum: 0,
                pageSize: 0,
            };
            data.param.adminId = sessionStorage.getItem('adminId');

            planList(data).then((res) => {
                if (res.code == 200) {
                    if (res.data.total == 0) {
                        this.dialogForce = false;
                        this.getData();

                        if (this.btnP.depSearch) {
                            this.getDepartmentList();
                        }
                        if (this.btnP.searchUser && !this.btnP.depSearch) {
                            this.$refs.department.getUserList(
                                sessionStorage.getItem('departmentId')
                            );
                        }
                    } else {
                        this.dialogForce = true;
                        setTimeout(() => {
                            this.$refs.force.getData(res.data.list);
                        }, 0);
                    }
                }
            });
        },
        getDate() {
            var now = new Date();
            var year = now.getFullYear(); //得到年份
            var month = now.getMonth(); //得到月份
            month = month + 1;
            month = month.toString().padStart(2, '0');
            var defaultDate = `${year}-${month}`; //
            return defaultDate;
        },
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            // console.log(btnArr);
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
                if (btnArr[i].children && btnArr[i].children.length > 0) {
                    btnArr[i].children.forEach((item) => {
                        btn['' + item.route] = true;
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((itm) => {
                                btn['' + itm.route] = true;
                            });
                        }
                    });
                }
            }
            this.btnP = btn;
            this.planList();
            // if (!this.dialogForce) {
            //     this.getData();

            //     if (btn.depSearch) {
            //         this.getDepartmentList();
            //     }
            //     if (btn.searchUser && !btn.depSearch) {
            //         this.getUserList(sessionStorage.getItem('departmentId'));
            //     }
            // }
        },

        getData() {
            setTimeout(() => {
                this.$refs.table.getData(
                    this.listType,
                    this.searchVal,
                    this.currentPage,
                    this.pagesize,
                    this.btnP
                );
            }, 0);
        },
        briefData(data) {
            this.bData = data || {};
        },
        briefData2(data) {
            this.cData = data || 0;
        },
        clickStatus(e) {
            e === this.listType ? (this.listType = '') : (this.listType = e);
            this.searchVal.adminId = '';
            this.searchVal.departmentId = '';
            this.searchVal.name = '';
            this.getTableData();
        },
        clickStatus2(e) {
            e === this.searchVal.status
                ? (this.searchVal.status = '')
                : (this.searchVal.status = e);

            this.getTableData();
        },
        getTableData() {
            this.currentPage = 1;
            this.getData();
        },
        totalNum(num) {
            this.total = num;
        },
        searchData(data) {
            this.pagesize = 20;
            this.pageNum = 1;
            this.searchVal.departmentId = data.departmentId;
            this.searchVal.adminId = data.adminId;
            this.getData();
        },

        // 获取部门
        getDepartmentList() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.departmentList = res.data;
                    this.$refs.department.getData(this.btnP, res.data);
                }
            });
        },
        // 新增回款计划
        addMoney(row) {
            setTimeout(() => {
                if (row.id) {
                    this.$router.push('/addReturnPlan');
                } else {
                    this.$router.push({
                        path: '/addReturnPlan',
                        query: {
                            disabled: true,
                        },
                    });
                }
            }, 0);
        },
        // 编辑
        onUpdate(row) {
            this.dialogUpdate = true;
            this.form = row;
            this.onePlan = JSON.parse(JSON.stringify(row));
            this.getContr();
        },
        onSubmitPlan() {
            if (!this.form.returnAmount) {
                return this.$message.error('请输入回款金额');
            }
            if (Number(this.form.returnAmount) === 0) {
                return this.$message.error('请输入回款金额');
            }
            if (!this.form.returnTime) {
                return this.$message.error('请选择回款年月');
            }
            let data = {
                param: {
                    id: this.form.id,
                    contractA: this.form.contractA
                        ? this.form.contractA
                        : this.onePlan.contractA,
                    contractB: this.onePlan.contractB,
                    returnAmount: Number(this.form.returnAmount).toFixed(2),
                    returnTime: this.form.returnTime,
                    remark: this.form.remark,
                },
            };
            updatePlan(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.handleDialogClose();
                }
            });
        },
        // 申请发票
        onApply(row) {
            this.$router.push({
                path: '/addApplyinvoice',
                query: {
                    contracId: row.contractId,
                },
            });
            sessionStorage.setItem('moneyId', row.id);
        },
        onDetail(row) {
            this.$router.push({
                path: 'condetails',
                query: {
                    index: 2,
                    id: row.contractId,
                },
            });
        },
        // 获取合同
        getContr() {
            let data = {
                param: {
                    id: this.onePlan.contractId,
                },
            };
            contracGet(data).then((res) => {
                if (res.code == 200) {
                    this.contract = res.data;
                    this.allMoney = 0;
                    res.data.contractReturnPlanBOList.forEach((item) => {
                        this.allMoney += item.returnAmount;
                    });
                }
            });
        },
        onMatching(data) {
            this.dialogMatching = true;
            setTimeout(() => {
                this.$refs.matching.$data.tradeNoVal = '';
                this.$refs.matching.getData(data);
            }, 0);
        },
        onDel(data) {
            this.onePLan = data;
            this.dialogDel = true;
        },
        onSubmitDel() {
            let data = {
                param: {
                    id: this.onePLan.id,
                },
            };
            delPlan(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.handleClose();
                }
            });
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.currentPage = 1;
            this.getData();
        },
        handleDrawerClose() {
            this.drawerApply = false;
            this.drawerUpdate = false;
            this.getData();
        },
        handleDrawerClose2() {
            this.drawerAdd = false;
            this.getData();
        },
        handleDialogClose() {
            this.dialogUpdate = false;
            this.getData();
        },
        handleClose() {
            this.dialogMatching = false;
            this.dialogDel = false;
            this.getData();
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.clue_box {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    .search-box {
        width: 100%;
        // background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        display: flex;
        span {
            font-size: 12px;
            font-weight: 600;
            color: #000;
            margin: 0 16px;
        }
        .search-one {
            // margin: 10px 0 12px 0;
            display: flex;
            // justify-content: space-between;

            background-color: #fff;
            margin-right: 10px;
            flex-direction: column;
            flex: 1;
            .left {
                padding-top: 12px;
                button {
                    background: #2370eb;
                    border-radius: 2px;
                    border-color: #2370eb;
                    margin-right: 16px;
                }
                .el-range-editor--small.el-input__inner {
                    vertical-align: middle;
                    margin-left: 19px;
                }
            }
            .right {
                background: #2370eb;
                border-radius: 2px;
                border-color: #2370eb;
                margin-right: 16px;
            }
        }
        .search_right {
            flex: 0.4;
            background-color: #fff;
            height: 96px;
            font-size: 13px;
        }
    }
    .table-title {
        background: #fff;
        padding: 16px;
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            span {
                margin-right: 40px;
            }
        }
    }
    .table {
        flex: 1;
        min-height: 0;
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .clue_btn {
        margin-top: 10px;
        position: absolute;
        top: -50px;
        right: 0;
        span {
            display: inline-block;
            padding: 10px 20px;
            margin-left: 15px;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            background-color: #fff;
        }
    }
}
.clue_assist_body {
    padding: 42px 74px 100px;
    .user {
        padding-bottom: 16px;
        display: flex;
        align-items: center;
        .left-spot {
            display: inline-block;
            width: 8px;
            height: 8px;
            background: #2370eb;
            border-radius: 50%;
            margin-right: 20px;
            margin-left: -22px;
        }
    }
    .user:last-child {
        .left-spot {
            display: inline-block;
            width: 7px;
            height: 7px;
            background: #fff;
            border: 1px solid #2370eb;
            border-radius: 50%;
            margin-right: 20px;
            margin-left: -22px;
        }
    }
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: space-between;
    button {
        width: 72px;
    }
}

.dialog_info {
    width: 80%;
    margin: 25px auto 150px;
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
}
/deep/ .el-textarea__inner {
    resize: none;
}
.search-two {
    margin-bottom: 10px;
    button {
        background: #2370eb;
        border-radius: 2px;
        border-color: #2370eb;
        margin-right: 16px;
    }
}
.dilog_box {
    .el-form {
        width: 80%;
        margin: 20px auto 80px;
    }
}
.search_right {
    display: flex;
    > div:first-child {
        flex: 1;
    }
    > div:last-child {
        flex: 0.7;
    }
    > div {
        display: flex;
        flex-direction: column;
        padding: 16px;
        img {
            vertical-align: -5px;
            margin-right: 5px;
        }
    }
}
.cover {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    min-width: 1360px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5;
}
.dialogForce_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 650px;
    height: 500px;
    background-color: #fff;
    .dialogForce_title {
        height: 56px;
        line-height: 56px;
        padding-left: 20px;
        background: #f5f6f7;
    }
}
</style>
