<template>
    <div class="force_box">
        <p>以下回款计划的回款年月已超时，请点击修改预计回款年月</p>
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="390px"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '600',
            }"
        >
            <el-table-column width="50px" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="回款金额" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.returnAmount || 0 }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="客户名称" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.companyName || '- -' }}
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="回款年月">
                <template slot-scope="scope">
                    <el-date-picker
                        v-model="scope.row.returnTime"
                        type="month"
                        size="small"
                        :clearable="false"
                        style="width: 100px"
                        placeholder="请选择年月"
                        @change="
                            (val) => {
                                update(scope.row, val);
                            }
                        "
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :picker-options="expireTimeOption"
                    >
                    </el-date-picker>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { updatePlan } from '@/api/contr/money.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            tableData: [],
            expireTimeOption: {
                disabledDate(date) {
                    // disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
                    return date.getTime() < Date.now();
                },
            },
        };
    },
    components: {},
    created() {},
    methods: {
        getData(data) {
            console.log(data);
            this.tableData = data;
        },
        update(row, val) {
            let data = {
                param: {
                    id: row.id,
                    returnTime: val,
                },
            };
            updatePlan(data).then((res) => {
                if (res.code == 200) {
                    this.$emit('updateData');
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.force_box {
    > p {
        padding: 16px 34px;
        font-size: 14px;
        font-weight: 600;
        color: #333;
    }

    //     /deep/ .el-input--small .el-input__inner {
    //         height: 20px !important;
    //         line-height: 20px !important;
    //     }
    /deep/ .el-input.is-disabled .el-input__inner {
        background-color: transparent !important;
    }
    /deep/ .el-table .el-table__cell {
        padding: 5px 0;
    }
    /deep/ .el-input--prefix .el-input__inner {
        padding: 0;
    }
    /deep/ .el-input__inner {
        border: none;
    }
    /deep/ .el-input__prefix {
        opacity: 0;
    }
    /deep/ .el-input--small {
        font-size: 14px !important;
    }
}
</style>
